export const menuItems = [
  {
    path: "/",
    text: "처음으로",
  },
  {
    path: "/about",
    text: "도니에대해",
  },
  {
    path: "/about",
    text: "도니의일정",
  },
  {
    path: "/contact",
    text: "메시지보내기",
  },
  {
    path: "/products",
    text: "Donny직업",
  },
]
